import { renderToStaticMarkup } from "react-dom/server";
import { divIcon, LatLngExpression } from "leaflet";
import vrstevnica_108 from "../data/vrstevnica_108.json";
import vrstevnica_110 from "../data/vrstevnica_110.json";
import vrstevnica_111_25 from "../data/vrstevnica_111_25.json";
import vrstevnica_113 from "../data/vrstevnica_113.json";

import {
  MapContainer,
  // Popup,
  TileLayer,
  GeoJSON,
  Marker,
  useMap,
} from "react-leaflet";
// import { initiateSocket, disconnectSocket, onMessage } from "../helpers/socket";
import useGeolocation from 'react-hook-geolocation'

import "./Map.css";
let centerPosition: LatLngExpression = [48.787094, 22.014426];
let position = centerPosition;


function CurrentPosition() {
  const geolocation = useGeolocation({
    enableHighAccuracy: true, 
    maximumAge: 15000, 
    timeout: 12000
  });
  const map = useMap();
  if (geolocation.latitude && geolocation.longitude) {
    position = [geolocation.latitude, geolocation.longitude];
    map.panTo(position, {animate: true, duration: 0.5});
  }

  const iconMarkup = renderToStaticMarkup(
    <div className="ring-container">
        <div className="ringring"></div>
        <div className="circle"></div>
    </div>  );
  const customMarkerIcon = divIcon({
    className: "custom-div-icon",
    html: iconMarkup,
  });
  return (
    <Marker
    position={position}
    icon={customMarkerIcon}
  ></Marker>
  )
}

export function Map() {
  return (
    <>
      <MapContainer center={centerPosition} zoom={14} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <GeoJSON
          key="vrstevnica_113"
          data={vrstevnica_113.features as any}
          style={{ weight: 1, color: "#D8FFFF", fill: true, fillOpacity: 1 }}
        />
        <GeoJSON
          key="vrstevnica_111"
          data={vrstevnica_111_25.features as any}
          style={{ weight: 1, color: "#BFF7F7", fill: true, fillOpacity: 1 }}
        />
        <GeoJSON
          key="vrstevnica_110"
          data={vrstevnica_110.features as any}
          style={{ weight: 1, color: "#a2f1f1", fill: true, fillOpacity: 1 }}
        />
        <GeoJSON
          key="vrstevnica_108"
          data={vrstevnica_108.features as any}
          style={{ weight: 1, color: "#8fd6d6", fill: true, fillOpacity: 1 }}
        />

        <CurrentPosition />
      </MapContainer>
    </>
  );
}

export default Map;
